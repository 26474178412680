import table from '@/mixin/table'
import errors from '@/mixin/errors'
import AccordionItem from '@/components/Page/AccordionItem'
import TextToClipboard from '@/components/TextToClipboard'
import CInputTags from '@/components/CInputTags'
import reloadData from '@/mixin/reloadData'
import modalComponent from '@/mixin/modalComponent'
import InfoIcon from '@/views/Setting/Account/CompanyDetails/components/InfoIcon'
import CSelectSearch from '../../../../components/CSelectSearch'

export default {
  inject: ['toast'],
  mixins: [table, errors, reloadData, modalComponent],
  components: {
    AccordionItem,
    CInputTags,
    TextToClipboard,
    InfoIcon,
    CSelectSearch,
  },
  mounted() {
    this.$nextTick(() => {
      this.mountedComponent = true
    })
    this.fetchList('')
  },
  data() {
    return {
      mountedComponent: false,
      tooltipText:
        "`This tool allows you to insert merge fields from the list to the 'Name', 'Email Subject', 'Email Message'. While editing one of these fields select the needed merge field, and it will be automatically added to the input`",
      search: '',
      fields: [
        { key: 'id', label: '#', _style: '', sorter: false },
        { key: 'name', label: 'Email Template', _style: '', sorter: false },
      ],
      templates: [],
      loadingTable: false,
      editId: null,
      deleteId: null,
      updateModal: false,
      code: '',

      name: '',
      subject: '',
      message: '',
      messageKey: '',
      tags: [],
      template: {},
      ruleFields: [
        {
          key: 'name',
          rules: [{ name: 'required' }, { name: 'max', params: 255 }],
        },
        {
          key: 'subject',
          rules: [{ name: 'required' }, { name: 'max', params: 255 }],
        },
        {
          key: 'message',
          rules: [{ name: 'required' }, { name: 'max', params: 2000 }],
        },
        { key: 'tags', rules: [] },
      ],

      fieldsList: [],
    }
  },
  watch: {
    editId() {
      this.fetchDataEdit()
    },
  },
  methods: {
    copy() {
      this.toast('success', 'Text successfully copied')
      navigator.clipboard.writeText(this.code)
    },
    fetchList(search) {
      this.$http.template
        .getCodeList({ params: { search: search, type: 'email' } })
        .then((res) => {
          this.fieldsList = res.data.data.map((i) => ({
            value: i.placeholder,
            label: i.name,
          }))
        })
    },
    fetchDataEdit() {
      this.updateModal = false
      this.errors = {}
      if (this.editId) {
        this.$http.emailTemplate
          .getTemplate({ params: { template_id: this.editId } })
          .then((res) => {
            this.template = res.data.data
            this.name = res.data.data.name
            this.subject = res.data.data.subject
            this.message = res.data.data.message
            this.messageKey = new Date()
            this.tags = res.data.data.tags.map((i) => i.name)
            this.code = ''
          })
      } else {
        this.template = {}
        this.name = ''
        this.subject = ''
        this.message = ''
        this.tags = []
        this.code = ''
      }
    },
    fetchData() {
      this.key++
      this.loadingTable = true
      this.$http.emailTemplate
        .getTemplatesList({
          params: {
            // per_page: this.perPage / 10,
            // page: 1,
            search: this.search,
            //   sort_field: this.sort && this.sort.column,
            //   sort_direction: this.sort && this.sort.state
          },
        })
        .then((res) => {
          this.loadingTable = false
          this.templates = res.data.data
          this.errors = {}
          this.key++
          this.fetchDataEdit()
          // this.pages = res.meta.last_page
          // if (this.activePage > this.pages) {
          //   this.activePage = this.activePage - 1
          // }
        })
        .catch(() => {
          this.loadingTable = false
        })
    },
    deleteTemplate(id) {
      this.$modal
        .open({
          close: 'Cancel',
          success: 'Delete',
          label: 'Delete Template',
          sublabel: 'Are you sure you want to delete email template?',
        })
        .then(() => {
          this.deleteTemplate(id)

          this.$http.emailTemplate
            .deleteTemplate({
              template_id: id,
            })
            .then((response) => {
              this.$modal.close()
              this.fetchData()
              this.toast('success', response.data.message)
              this.editId = null
            })
            .catch(({ response }) => {
              this.errors = response.data.errors
                ? this.getErrors(response.data.errors)
                : {}
              this.toast('error', response.data.message)
            })
        })
    },
    addTemplate() {
      if (this.updateModal) {
        this.createModal()
      } else {
        this.$modal.close()
        this.editId = null
        this.template = {}
        this.name = ''
        this.subject = ''
        this.message = ''
        this.tags = []
        this.code = ''
      }
    },
    createModal() {
      this.$modal
        .open({
          close: 'No',
          success: 'Yes',
          label: 'Create New Template',
          sublabel:
            'You have unsaved changes. Are you sure, you want to cancel them?',
        })
        .then(() => {
          this.updateModal = false
          this.addTemplate()
        })
    },
    closeModalHandler() {
      this.$modal
        .open({
          close: 'No',
          success: 'Yes',
          label: 'Cancel changes?',
          sublabel:
            'You have unsaved changes. Are you sure, you want to cancel them?',
        })
        .then(() => {
          this.leavePage()
        })
    },
    cancel() {
      if (this.updateModal) {
        this.closeModalHandler()
      } else {
        this.fetchData()
        this.updateModal = false
      }
    },
    leavePage() {
      this.$modal.close()
      this.updateModal = false
      this.fetchData()
    },
    save() {
      if (this.validate()) {
        if (this.editId) {
          this.$http.emailTemplate
            .editTemplate({
              template_id: this.editId,
              name: this.name,
              subject: this.subject,
              message: this.message,
              tags: this.tags,
            })
            .then((res) => {
              this.toast('success', res.data.message)
              this.fetchData()
            })
            .catch(({ response }) => {
              this.errors = response.data.errors
                ? this.getErrors(response.data.errors)
                : {}
              this.toast('error', response.data.message)
            })
        } else {
          this.$http.emailTemplate
            .createTemplate({
              name: this.name,
              subject: this.subject,
              message: this.message,
              tags: this.tags,
            })
            .then((res) => {
              this.editId = res.data.email_template?.id

              this.toast('success', res.data.message)
              this.fetchData()
            })
            .catch(({ response }) => {
              this.errors = response.data.errors
                ? this.getErrors(response.data.errors)
                : {}
              this.toast('error', response.data.message)
            })
        }
      }
    },
  },
}
