<template>
  <span class="text-to-clipboard clickable">
    <Popper content="Copy to clipboard">
      <Transition>
        <CIcon
          v-if="copied"
          class="text-copied"
          name="cilCheck"
        />
      </Transition>
      <CIcon
        class="clickable"
        size="lg"
        name="cilClipboard"
        @click.prevent="handleClick"
      />
    </Popper>
  </span>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import Popper from '@/components/Popper.vue'

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
})

const copied = ref(false)

const handleClick = () => {
  navigator.clipboard.writeText(props.text).then(
    () => {
      copied.value = true
      setTimeout(() => {
        copied.value = false
      }, 1200)
    },
    () => {
      //
    },
  )
}
</script>

<style scoped>
.text-copied {
  position: absolute;
  top: 3px;
  right: 2px;
}
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-enter-active,
.v-leave-to {
  opacity: 0;
}
</style>
