<template>
  <div class="settingEmailTemplates">
    <CCard>
      <teleport v-if="mountedComponent" to="#header-nav">
        <CBreadcrumbItem active> Email Templates </CBreadcrumbItem>
      </teleport>

      <CCardBody>
        <CRow>
          <CCol class="settingEmailTemplates__header mobile-hide">
            <div class="settingEmailTemplates__label">
              Email Template Library
            </div>
            <CButton color="primary" @click="addTemplate">
              Add New Email Template
            </CButton>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="12" md="6">
            <CAccordion>
              <AccordionItem
                :mobile-label="'Email Template Library'"
                :item-key="1"
              >
                <CInputGroup :style="{ marginBottom: '10px' }">
                  <CInputGroupText><CIcon name="cil-search" /></CInputGroupText>
                  <CFormInput
                    v-model="search"
                    placeholder="Search"
                    @blur="fetchData"
                  />
                </CInputGroup>

                <SmartTable
                  style="padding-left: 0.75rem"
                  :items="templates"
                  :items-per-page="parseInt(templates.length)"
                  :columns="fields.map((i) => ({ ...i, filter: false }))"
                  :sorter-value="sort"
                  :loading="loadingTable"
                  infinity
                  @sorter-change="onSort"
                >
                  <!-- @infinity-update="() => perPage = perPage + 10" -->
                  <template #button="{ item }">
                    <div class="d-flex">
                      <CIcon
                        name="cil-pen"
                        class="m-2 pointer"
                        @click="editId = item.id"
                      />
                      <CIcon
                        name="cil-trash"
                        class="m-2 pointer"
                        @click="deleteTemplate(item.id)"
                      />
                    </div>
                  </template>
                </SmartTable>
                <CButton
                  color="primary"
                  class="w-100 pc-hide"
                  @click="addTemplate"
                >
                  Add New Email Template
                </CButton>
              </AccordionItem>
            </CAccordion>
          </CCol>
          <CCol sm="12" md="6">
            <CAccordion>
              <AccordionItem :mobile-label="'Email Template'" :item-key="2">
                <CRow>
                  <CCol sm="12" md="6">
                    <CFormLabel class="mt-3">Email Template Name</CFormLabel>
                    <CInputGroup>
                      <CFormInput
                        v-model="name"
                        v-with-shortcodes-suggestion
                        placeholder="Name"
                        :style="`border-color: ${errors.name ? 'red' : ''}`"
                        @blur="
                          () => {
                            updateModal = true
                            validate('name')
                          }
                        "
                      />
                    </CInputGroup>
                    <CFormText
                      v-if="errors.name"
                      :style="`color: ${errors.name && 'red'}`"
                      >{{ errors.name }}</CFormText
                    >
                  </CCol>
                  <CCol sm="12" md="6">
                    <CFormLabel
                      class="d-flex justify-content-between align-items-center mt-3"
                      >Merge Fields
                      <InfoIcon :tooltip-text="tooltipText" />
                    </CFormLabel>
                    <div
                      class="d-flex align-items-center justify-content-between w-100"
                    >
                      <CSelectSearch
                        v-model="code"
                        placeholder="Select Merge Field"
                        :options="fieldsList"
                        :style="`max-width: calc(100% - ${
                          code ? '20px' : '0'
                        }); width: 100%`"
                        :search="true"
                        @fetchOptions="fetchList"
                      />
                      <CIcon
                        v-if="code"
                        v-c-tooltip="'Copy to clipboard'"
                        class="clickable m-1"
                        size="lg"
                        name="cilClipboard"
                        style="min-width: 20px"
                        @click="copy"
                      />
                    </div>
                    <!-- <div v-if="code" class="d-flex mt-2">
                      <CInputGroup class="position-relative">
                        <CFormInput
                          custom-class-name="no-append"
                          class="no-append"
                          :key="code"
                          :disabled="true"
                          placeholder=""
                          :model-value="`${code}`"
                        />
                        <text-to-clipboard :text="code" />

                      </CInputGroup>
                    </div> -->
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <CFormLabel class="mt-3">Email Subject</CFormLabel>
                    <CInputGroup>
                      <CFormInput
                        v-model="subject"
                        v-with-shortcodes-suggestion
                        placeholder="Subject"
                        :style="`border-color: ${errors.subject ? 'red' : ''}`"
                        @blur="
                          () => {
                            updateModal = true
                            validate('subject')
                          }
                        "
                      />
                    </CInputGroup>
                    <CFormText
                      v-if="errors.subject"
                      :style="`color: ${errors.subject && 'red'}`"
                      >{{ errors.subject }}</CFormText
                    >
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <CFormLabel class="mt-3">Email Message</CFormLabel>
                    <CInputGroup>
                      <CFormTextarea
                        :key="`ms-${messageKey}`"
                        v-model="message"
                        v-autosize
                        v-with-shortcodes-suggestion
                        class="form-control"
                        placeholder="Message"
                        :style="`border-color: ${errors.message ? 'red' : ''}`"
                        @blur="
                          () => {
                            updateModal = true
                            validate('message')
                          }
                        "
                      />
                    </CInputGroup>
                    <CFormText
                      v-if="errors.message"
                      :style="`color: ${errors.message && 'red'}`"
                      >{{ errors.message }}</CFormText
                    >
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CFormLabel class="mt-3">Tags</CFormLabel>
                    <CInputTags
                      v-model="tags"
                      placeholder="Tags"
                      :style="{ 'border-color': errors.tags ? 'red' : '' }"
                      @blur="
                        () => {
                          updateModal = true
                          validate('tags')
                        }
                      "
                    />
                    <CFormText
                      v-if="errors.tags"
                      :style="`color: ${errors.tags ? 'red' : ''}`"
                      >{{ errors.tags }}</CFormText
                    >
                  </CCol>
                </CRow>

                <CRow class="mt-3 mobile-hide">
                  <CCol>
                    <CButton class="m-2" color="primary" @click="save">
                      {{ editId ? 'Save' : 'Create' }}
                    </CButton>
                    <CButton
                      class="m-2"
                      color="primary"
                      variant="outline"
                      :disabled="!updateModal"
                      @click="cancel"
                    >
                      Cancel
                    </CButton>
                  </CCol>
                </CRow>
                <CRow v-if="template.id" class="mt-2">
                  <CCol
                    sm="12"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>Last Updated</span>
                    <span>{{ template.modified_by.full_name }}</span>
                  </CCol>
                  <CCol
                    sm="12"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>Updated</span>
                    <span style="text-decoration: underline">{{
                      $BtoF({ value: template.updated_at, time: true })
                    }}</span>
                  </CCol>
                </CRow>
              </AccordionItem>
            </CAccordion>
          </CCol>
        </CRow>
        <CRow>
          <CCol
            class="d-flex align-items-center justify-content-center flex-column pc-hide"
          >
            <CButton class="m-2 w-100" color="primary" @click="save">
              Save
            </CButton>
            <CButton
              class="m-2 w-100"
              color="primary"
              variant="outline"
              :disabled="!updateModal"
              @click="cancel"
            >
              Cancel
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
