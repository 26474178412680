<template>
  <Multiselect
    :model-value="modelValue"
    mode="tags"
    :placeholder="placeholder"
    :options="options"
    :searchable="true"
    :create-tag="true"
    :loading="loader"
    :style="{ ...style }"
    @change="setValue"
    @search-change="searchChange"
    @close="$emit('blur')"
  />
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    Multiselect,
  },
  props: {
    modelValue: {},
    placeholder: { type: String, default: '' },
    style: {},
  },
  emits: ['update:modelValue', 'blur'],
  data() {
    return {
      search: [],
      loader: false,
    }
  },
  computed: {
    options() {
      return [...this.modelValue, ...this.search]
    },
  },
  methods: {
    searchChange(event) {
      if (event) {
        this.loader = true
        this.$http.app
          .getTags({ params: { search: event } })
          .then((res) => {
            this.loader = false
            this.search = res.data.data.map((i) => i.name)
          })
          .catch(() => {
            this.search = []
            this.loader = false
          })
      } else {
        this.search = []
      }
    },
    setValue(event) {
      this.$emit('blur')
      this.$emit('update:modelValue', event)
    },
  },
}
</script>

<style>
span.multiselect-tag {
  background: #005d9d !important;
}
</style>
